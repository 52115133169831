import { createAction } from "@reduxjs/toolkit";
import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "../apiconfig";

import {
    startLoginIn as startLoginInSlice,
    successLoginIn as successLoginInSlice,
    errorLoginIn as errorLoginInSlice,
    logOut as logOutSlice,
} from "../slices/login"

export const startLoginIn = createAction("START_LOGIN_IN");
export const successLoginIn = createAction("SUCCESS_LOGIN_IN");
export const errorLoginIn = createAction("ERROR_LOGIN_IN");
export const logOut = createAction("LOG_OUT");

type logUserInProps = {
    username:string;
    password:string;
    
}
export const logUserIn = ({username,password}:logUserInProps)=>async (dispatch:any)=>{
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type":"application/json"},
        body:JSON.stringify({
            username:username,
            password:password
            })
    }

    try {
        dispatch(startLoginInSlice());
        let response
        let locationForApi = apiLocation
        if(locationForApi === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"auth/login",requestOptions);
        }
        else{
            response = await fetch(apiServerURLRoot+"auth/login",requestOptions);
        }

        const data = await response.json();
        if (data.error){
            dispatch(errorLoginInSlice(data.error));
        } else{
            dispatch(successLoginInSlice({data}));
        }
    } catch (error) {
        dispatch(errorLoginInSlice({error}));
    }
};