import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  requestingToGetIceMachineConfigById: false,
  successRequestingToGetIceMachineConfigById: false,
  errorRequestingToGetIceMachineConfigById: false,
  errorRequestingToGetIceMachineConfigByIdData: {},
  iceMachinConfigByIdData: {},
  unauthorized: true,
};

const getIceMachineConfigs = createSlice({
  name: "get-ice-machine-config-by-id",
  initialState,
  reducers: {
    requestToGetIceMachineConfigById(state) {
      state.requestingToGetIceMachineConfigById = true;
    },
    successToGetIceMachineConfigById(state, action) {
      state.requestingToGetIceMachineConfigById = false;
      state.successRequestingToGetIceMachineConfigById = true;
      state.errorRequestingToGetIceMachineConfigById = false;
      state.iceMachinConfigByIdData = action.payload.data.data;
      console.log(action.payload.data.data)
    },
    errorToGetIceMachineConfigById(state, action) {
      state.requestingToGetIceMachineConfigById = false;
      state.successRequestingToGetIceMachineConfigById = false;
      state.errorRequestingToGetIceMachineConfigById = true;
    },
  },
});

const { actions, reducer } = getIceMachineConfigs;

const {
  requestToGetIceMachineConfigById,
  successToGetIceMachineConfigById,
  errorToGetIceMachineConfigById,
} = actions;

export {
  requestToGetIceMachineConfigById,
  successToGetIceMachineConfigById,
  errorToGetIceMachineConfigById,
};

export default reducer;
