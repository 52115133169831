import { useEffect, useState } from "react";
import "./IceMachineConfigView.css";
import { useDispatch, useSelector } from "react-redux";
import { iceMachinesConfigUpdate } from "../../redux/actions/iceMachineConfigUpdate";
import { iceMachinesConfigGetItemById } from "../../redux/actions/iceMachineConfigGetItemById";

type iceMachineConfigViewProps = {
  iceMachineName: string;
  iceMachineId: number;
  onClose: any;
  ciclosSinInternet: number;
};
export default function IceMachineConfigView({
  iceMachineId,
  iceMachineName,
  onClose,
  ciclosSinInternet,
}: iceMachineConfigViewProps) {
  /**-------------------------------------------------------------------------------------*/
  /** STATE VARIABLES HERE                                                                */
  /**-------------------------------------------------------------------------------------*/
  const [noInternetCycles, setNoInternetCycles] = useState(ciclosSinInternet);
  const [saveButtonPressed,setSaveButtonPressed] = useState(false)
  const dispatch:any = useDispatch();

  /**-----------------------------------------------------------------*/
  /** REDUX SELECTORS HERE                                            */
  /**-----------------------------------------------------------------*/
  const {
    requestingToUpdateIceMachineConfig,
    successRequestingToUpdateIceMachineConfig,
    errorRequestingToUpdateIceMachineConfig,
    errorRequestingToUpdateIceMachineConfigData,
    iceMachinConfigData,
    unauthorized
  } = useSelector((state: any) => state.iceMachineConfigUpdateReducer);

  const {
    requestingToGetIceMachineConfigById,
    successRequestingToGetIceMachineConfigById,
    errorRequestingToGetIceMachineConfigById,
    errorRequestingToGetProductionStatsData,
    iceMachinConfigByIdData,
  } = useSelector((state: any) => state.iceMachineConfigGetItemByIdReducer);

  /**-------------------------------------------------------------------------------------*/
  /** HANDLERS HERE                                                                       */
  /**-------------------------------------------------------------------------------------*/
  const handleNoInternetCyclesSelectChange = (event: any) => {
    setNoInternetCycles(event.target.value);
  };
  const handleSaveConfigButtonClick = () => {
    setSaveButtonPressed(true)
    var tokenillo = sessionStorage.getItem("token");
    dispatch(iceMachinesConfigUpdate(tokenillo,noInternetCycles,iceMachineId))
    
  };
  /**-------------------------------------------------------------------------------------*/
  /** USE EFFECTS HERE                                                                    */
  /**-------------------------------------------------------------------------------------*/
  useEffect(()=>{
    console.log("ME MOUNTING")
    var tokenillo = sessionStorage.getItem("token");
    dispatch(iceMachinesConfigGetItemById(tokenillo,iceMachineId))
    setSaveButtonPressed(false);
    }
    ,[])
    useEffect(()=>{
      console.log(iceMachinConfigByIdData)
      setNoInternetCycles(iceMachinConfigByIdData.ciclosSinInternet)
    },[iceMachinConfigByIdData])
    useEffect(()=>{
      console.log("ENTRO PORQUE SALVA")
      if (successRequestingToUpdateIceMachineConfig===true && saveButtonPressed === true) {
        onClose();
      }
      setSaveButtonPressed(false)
    },[successRequestingToUpdateIceMachineConfig,saveButtonPressed])
  return (
    <div className="IceMachineConfigView--MainContainer">
      <div
        className="header--title_secondary"
        style={{ color: "black", display: "flex" }}
      >
        Set settings for: {iceMachineName}
      </div>
      <div className="IceMachineConfigView--InputContainer">
        <div className="input--label--secondary">No Internet Cycles</div>
        <div style={{ display: "flex" }}>
          <input
            type="range"
            max={100}
            min={1}
            onChange={handleNoInternetCyclesSelectChange}
            value={noInternetCycles}
          />
          <div className="input--label--secondary">{noInternetCycles}</div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}
      >
        <button
          onClick={onClose}
          style={{ margin: "10px" }}
          className="button--secondary"
        >
          CANCEL
        </button>
        <button onClick={handleSaveConfigButtonClick} style={{ margin: "10px" }} className="button--primary">
          SAVE
        </button>
      </div>
    </div>
  );
}
