import { createAction } from "@reduxjs/toolkit";
import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "../apiconfig";

import {
    requestToGetIceMachineConfigs as requestToGetIceMachineConfigsSlice,
    successToGetIceMachineConfigs as successToGetIceMachineConfigsSlice,
    errorToGetIceMachineConfigs as errorToGetIceMachineConfigsSlice,
} from "../slices/iceMachineConfigIndex"

export const requestToGetIceMachineConfigs = createAction("REQUEST_TO_GET_ICE_MACHINE_CONFIGS");
export const successToGetIceMachineConfigs = createAction("SUCCESS_TO_GET_ICE_MACHINE_CONFIGS");
export const errorToGetIceMachineConfigs = createAction("ERROR_TO_GET_ICE_MACHINE_CONFIGS");

export const iceMachinesConfigIndex = (token:any)=>async (dispatch:any)=>{
    const requestOptions = {
        method: "GET",
        headers: {"Authorization": token,"Content-Type":"application/json"}
    }

    try {
        dispatch(requestToGetIceMachineConfigsSlice());
        let response
        let locationForApi = apiLocation
        if(locationForApi === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"ice_machine_configs/",requestOptions);
        }
        else{
            response = await fetch(apiServerURLRoot+"ice_machine_configs/",requestOptions);
        }

        const data = await response.json();

        if (data.error){
            dispatch(errorToGetIceMachineConfigsSlice(data.error));
        } else{
            dispatch(successToGetIceMachineConfigsSlice({data}));
        }
    } catch (error) {
        dispatch(successToGetIceMachineConfigsSlice({error}));
    }
};