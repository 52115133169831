import { createAction } from "@reduxjs/toolkit";
import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "../apiconfig";

import {
    requestToGetProductionStats as requestToGetProductionStatsSlice,
    successToGetProductionStats as successToGetProductionStatsSlice,
    errorToGetProductionStats as errorToGetProductionStatsSlice,
} from "../slices/getProductionStats"

export const requestToGetProductionStats = createAction("REQUEST_TO_GET_PRODUCTION_STATS");
export const successToGetProductionStats = createAction("SUCCESS_TO_GET_PRODUCTION_STATS");
export const errorToGetProductionStats = createAction("ERROR_TO_GET_PRODUCTION_STATS");

type getProductionStatsProps = {
    token:string;
    userId:number;
    
}
export const getProductionStats = (token:any,userId:any)=>async (dispatch:any)=>{
    const requestOptions = {
        method: "GET",
        headers: {"Authorization": token,"Content-Type":"application/json"}
    }

    try {
        dispatch(requestToGetProductionStatsSlice());
        let response
        let locationForApi = apiLocation
        if(locationForApi === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"ice_machine_production_stats/"+userId,requestOptions);
        }
        else{
            response = await fetch(apiServerURLRoot+"ice_machine_production_stats/"+userId,requestOptions);
        }

        const data = await response.json();

        if (data.error){
            dispatch(errorToGetProductionStatsSlice(data.error));
        } else{
            dispatch(successToGetProductionStatsSlice({data}));
        }
    } catch (error) {
        dispatch(errorToGetProductionStatsSlice({error}));
    }
};