import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  requestingToUpdateIceMachineConfig: false,
  successRequestingToUpdateIceMachineConfig: false,
  errorRequestingToUpdateIceMachineConfig: false,
  errorRequestingToUpdateIceMachineConfigData: {},
  iceMachinConfigData: [],
  unauthorized: true,
};

const updateIceMachineConfig = createSlice({
  name: "update-ice-machine-config",
  initialState,
  reducers: {
    requestToUpdateIceMachineConfig(state) {
      state.requestingToUpdateIceMachineConfig = true;
    },
    successToUpdateIceMachineConfig(state, action) {
      state.requestingToUpdateIceMachineConfig = false;
      state.successRequestingToUpdateIceMachineConfig = true;
      state.errorRequestingToUpdateIceMachineConfig = false;
      state.iceMachinConfigData = action.payload.data.data;
    },
    errorToUpdateIceMachineConfig(state, action) {
      state.requestingToUpdateIceMachineConfig = false;
      state.successRequestingToUpdateIceMachineConfig = false;
      state.errorRequestingToUpdateIceMachineConfig = true;
    },
  },
});

const { actions, reducer } = updateIceMachineConfig;

const {
  requestToUpdateIceMachineConfig,
  successToUpdateIceMachineConfig,
  errorToUpdateIceMachineConfig,
} = actions;

export {
  requestToUpdateIceMachineConfig,
  successToUpdateIceMachineConfig,
  errorToUpdateIceMachineConfig,
};

export default reducer;
