import { createAction } from "@reduxjs/toolkit";
import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "../apiconfig";

import {
    requestToUpdateIceMachineConfig as requestToUpdateIceMachineConfigSlice,
    successToUpdateIceMachineConfig as successToUpdateIceMachineConfigSlice,
    errorToUpdateIceMachineConfig as errorToUpdateIceMachineConfigSlice,
} from "../slices/iceMachineConfigUpdate"

export const requestToUpdateIceMachineConfig = createAction("REQUEST_TO_UPDATE_ICE_MACHINE_CONFIG");
export const successToUpdateIceMachineConfig = createAction("SUCCESS_TO_UPDATE_ICE_MACHINE_CONFIG");
export const errorToUpdateIceMachineConfig = createAction("ERROR_TO_UPDATE_ICE_MACHINE_CONFIG");

export const iceMachinesConfigUpdate= (token:any,ciclosSinInternet:number,machineId:number)=>async (dispatch:any)=>{
    const requestOptions = {
        method: "PUT",
        headers: {"Authorization": token,"Content-Type":"application/json"},
        body:JSON.stringify({
            ciclosSinInternet:ciclosSinInternet,
            })
    }
    console.log(requestOptions)

    try {
        dispatch(requestToUpdateIceMachineConfigSlice());
        let response
        let locationForApi = apiLocation
        if(locationForApi === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"ice_machine_configs/"+machineId,requestOptions);
        }
        else{
            response = await fetch(apiServerURLRoot+"ice_machine_configs/"+machineId,requestOptions);
        }

        const data = await response.json();

        if (data.error){
            dispatch(errorToUpdateIceMachineConfigSlice(data.error));
        } else{
            dispatch(successToUpdateIceMachineConfigSlice({data}));
        }
    } catch (error) {
        dispatch(errorToUpdateIceMachineConfigSlice({error}));
    }
};