import React, { useState, useEffect } from "react";
import "./Toast.css"; // Import your CSS file for styling

type ToastProps = {
  title: any;
  message: any;
  duration: any;
  onClose: any;
  type: "error" | "warning" | "info" | "done";
};
const Toast = ({ title, message, duration, onClose, type }: ToastProps) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className={`toast ${type} ${isVisible ? "show" : "hide"}`}>
      <span className="material-icons toast-icon">{type}</span>
      <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
        <div>{title}</div>
        <div style={{textAlign:"left"}}>{message}</div>
      </div>
    </div>
  );
};

export default Toast;
