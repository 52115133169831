import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  requestingToGetIceMachineConfigs: false,
  successRequestingToGetIceMachineConfigs: false,
  errorRequestingToGetIceMachineConfigs: false,
  errorRequestingToGetIceMachineConfigsData: {},
  iceMachinConfigsData: [],
  unauthorized: true,
};

const getIceMachineConfigs = createSlice({
  name: "get-production-stats",
  initialState,
  reducers: {
    requestToGetIceMachineConfigs(state) {
      state.requestingToGetIceMachineConfigs = true;
    },
    successToGetIceMachineConfigs(state, action) {
      state.requestingToGetIceMachineConfigs = false;
      state.successRequestingToGetIceMachineConfigs = true;
      state.errorRequestingToGetIceMachineConfigs = false;
      state.iceMachinConfigsData = action.payload.data.data;
    },
    errorToGetIceMachineConfigs(state, action) {
      state.requestingToGetIceMachineConfigs = false;
      state.successRequestingToGetIceMachineConfigs = false;
      state.errorRequestingToGetIceMachineConfigs = true;
    },
  },
});

const { actions, reducer } = getIceMachineConfigs;

const {
  requestToGetIceMachineConfigs,
  successToGetIceMachineConfigs,
  errorToGetIceMachineConfigs,
} = actions;

export {
  requestToGetIceMachineConfigs,
  successToGetIceMachineConfigs,
  errorToGetIceMachineConfigs,
};

export default reducer;
