import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import loginInReducer from "../redux/slices/login"
import getProductionStatsReducer from "../redux/slices/getProductionStats";
import iceMachineConfigIndexReducer from "../redux/slices/iceMachineConfigIndex";
import iceMachineConfigUpdateReducer from "../redux/slices/iceMachineConfigUpdate";
import iceMachineConfigGetItemByIdReducer from "../redux/slices/iceMachineConfigGetItemById"

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    loginInReducer,
    getProductionStatsReducer,
    iceMachineConfigIndexReducer,
    iceMachineConfigUpdateReducer,
    iceMachineConfigGetItemByIdReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
