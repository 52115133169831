import "./IceMachineSummaryChip.css";
import activityIndicator from "../../assets/activityIndicator.svg";
import { Tooltip } from "react-tooltip";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductionStats } from "../../redux/actions/getProductionStats";
import Modal from "../Modal/Modal";
import IceMachineConfigView from "../IceMachineConfig/IceMachineConfigView";

//TODO: Implementara para que cuando le des actualizar a los datos pues se vea el moento en el que esta intentado
// cargar los datos. O sea pornerle un loader o algo asi cuando le das al boton actualizar.

type IceMachineSummaryChipProps = {
  iceMachineName: string;
  iceMachineId: number;
  noInternetCycles:number;
  realIceMachineId:number;
};
export default function IceMachineSummaryChip({
  iceMachineName,
  iceMachineId,
  noInternetCycles,
  realIceMachineId
}: IceMachineSummaryChipProps) {
  /**-----------------------------------------------------------------------*/
  /** CONSTANTS HERE                                                        */
  /**-----------------------------------------------------------------------*/
  const dispatch: any = useDispatch();
  /**-----------------------------------------------------------------------*/
  /** STATE VARIABLES HERE                                                  */
  /**-----------------------------------------------------------------------*/
  const [iceMachineActive, setIceMachineActive] = useState<boolean>(false);
  const [todayProductionStat, setTodayProductionStat] = useState<number>(0);
  const [thisWeekProductionStat, setThisWeekProductionStat] =
    useState<number>(0);
  const [thisMonthProductionStat, setThisMonthProductionStat] =
    useState<number>(0);
  const [thisYearhProductionStat, setThisYearProductionStat] =
    useState<number>(0);
  const [allTimeProductionStat, setAllTimeProductionStat] = useState<number>(0);
  const [lastThreeReportsStat, setLastThreeReportsStat] = useState([]);
  const [requestingDataUpdate,setRequestingDataUpdate] = useState<boolean>(false)
  const [showIceMachineConfigModal,setShowIceMachineConfigModal] = useState(false)

  /**-----------------------------------------------------------------*/
  /** REDUX SELECTORS HERE                                            */
  /**-----------------------------------------------------------------*/
  const {
    requestingToGetProductionStats,
    successRequestingToGetProductionStats,
    errorRequestingToGetProductionStats,
    errorRequestingToGetIceMachineConfigByIdData,
    productionStatsData,
    unauthorized,
  } = useSelector((state: any) => state.getProductionStatsReducer);

  /**-----------------------------------------------------------------------*/
  /** USE EFFECTS HERE HERE                                                 */
  /**-----------------------------------------------------------------------*/
  useEffect(() => {
    /** La logica aqui es la siguiente. Si los datos que han cambiado no son de mi id pues no actualizo nada.
     *  Esto me sirve para diferenciar y que solo se actualice el que se tiene que actualizar*/

    if (Object.keys(productionStatsData).length !== 0) {
      if (productionStatsData.data[0].id == iceMachineId) {
        setRequestingDataUpdate(false)
        setTodayProductionStat(productionStatsData.data[2].todayProduction);
        setThisWeekProductionStat(
          productionStatsData.data[3].thisWeekProduction
        );
        setThisMonthProductionStat(
          productionStatsData.data[4].thisMonthProduction
        );
        setThisYearProductionStat(
          productionStatsData.data[5].thisYearProduction
        );
        setAllTimeProductionStat(productionStatsData.data[6].allTimeProduction);

        /**Puede que no devuelva nada en los ultimos 3 reportes. Es algo que puede siceder sobre todo al principio*/
        if (
          Object.keys(productionStatsData.data[1].lastThreeReports).length !== 0
        ) {
          setLastThreeReportsStat(productionStatsData.data[1].lastThreeReports);
        }
        setIceMachineActive(productionStatsData.data[7].iceMachineActive)
        console.log(productionStatsData.data[7].iceMachineActive)
      } else {
        //console.log("NADA QUE VER CONMIGO");
      }
    }
  }, [productionStatsData]);

  useEffect(() => {
    console.log("REAL ICE MACHINE ID QUE  ME LLEGA")
    console.log(realIceMachineId)
    var tokenillo = sessionStorage.getItem("token");
    dispatch(getProductionStats(tokenillo,iceMachineId));
  }, []);

  /**-----------------------------------------------------------------------*/
  /** HANDLERS HERE                                                         */
  /**-----------------------------------------------------------------------*/
  const handleReloadButtonClick = () => {
    setRequestingDataUpdate(true)
    var tokenillo = sessionStorage.getItem("token");
    dispatch(getProductionStats(tokenillo, iceMachineId));
  };

  const handleSettingsButtonClick = ()=>{
    setShowIceMachineConfigModal(true)
  };

  const handleSettingsModalClose = ()=>{
    setShowIceMachineConfigModal(false)
  }

  const dummyHandler = ()=>{}
  /**-----------------------------------------------------------------------*/
  /** VIEW RETURN HERE                                                      */
  /**-----------------------------------------------------------------------*/
  return (
    <div className="IceMachineSummeryChip--MainContainer">
      <div className="IceMachineSummeryChip--HeaderContainer">
        <div className="IceMachineSummaryChip--MachineNameStatusContainer">
          <div className="header--title_secondary">{iceMachineName}</div>
          <div
            className={`material-icons  ${
              iceMachineActive === true
                ? "IceMachineSummaryChip--Status_Active"
                : "IceMachineSummaryChip--Status_Inactive"
            } iceMachineStatusTooltip`}
          >
            radio_button_checked
          </div>
        </div>
        <div className="IceMachineSummaryChip--ActionButtonsContainer">
          <span onClick={handleSettingsButtonClick} className="material-icons icon--primary settingsIconTooltip">
            settings
          </span>
          <span
            onClick={handleReloadButtonClick}
            className={`material-icons ${requestingDataUpdate?"icon--requesting-data":"icon--primary"}  reloadIconTooltip`}
          >
            refresh
          </span>
        </div>
      </div>

      <div className="IceMachineSummeryChip--StatsContainer">
        <div className="IceMachineSummeryChip--SingleStatContainer">
          <div className="header--subtitle_primary">Today:</div>
          <div className="header--subtitle_secondary">
            {todayProductionStat}
          </div>
        </div>
        <div className="IceMachineSummeryChip--SingleStatContainer">
          <div className="header--subtitle_primary">This week:</div>
          <div className="header--subtitle_secondary">
            {thisWeekProductionStat}
          </div>
        </div>
        <div className="IceMachineSummeryChip--SingleStatContainer">
          <div className="header--subtitle_primary">This month:</div>
          <div className="header--subtitle_secondary">
            {thisMonthProductionStat}
          </div>
        </div>
        <div className="IceMachineSummeryChip--SingleStatContainer">
          <div className="header--subtitle_primary">This year:</div>
          <div className="header--subtitle_secondary">
            {thisYearhProductionStat}
          </div>
        </div>
        <div className="IceMachineSummeryChip--SingleStatContainer">
          <div className="header--subtitle_primary">All time:</div>
          <div className="header--subtitle_secondary">
            {allTimeProductionStat}
          </div>
        </div>
      </div>

      <div className="IceMachineSummeryChip--LastReportsContainer">
        <div className="IceMachineSummeryChip--HeaderContainer">
          <div className="header--subtitle_secondary">Last 3 reports</div>
        </div>
        {lastThreeReportsStat?.map((report: any) => {
          return (
            <div className="IceMachineSummeryChip--SingleStatContainer">
              <div className="header--subtitle_primary">
                {report.fechaLocal}
              </div>
              <div className="header--subtitle_secondary">
                {report.dados_cortados}
              </div>
            </div>
          );
        })}
      </div>

      <Tooltip anchorSelect=".settingsIconTooltip" place="bottom">
        Edit Ice Machine Settings
      </Tooltip>
      <Tooltip anchorSelect=".reloadIconTooltip" place="bottom">
        Reload fresh data from Ice Machine
      </Tooltip>
      <Tooltip anchorSelect=".iceMachineStatusTooltip" place="bottom">
        {iceMachineActive === true
          ? "Ice Machine is active"
          : "Ice Machine is not active"}
      </Tooltip>
      <Modal children={<IceMachineConfigView ciclosSinInternet={noInternetCycles} onClose={handleSettingsModalClose} iceMachineName={iceMachineName} iceMachineId={realIceMachineId}/>} isOpen={showIceMachineConfigModal} onClose={handleSettingsModalClose}/>
    </div>
  );
}
