import "./LoginPage.css";
import MixologyIceLogoWhiteLetters from "../../assets/MixologyIceLogoWhiteLetters.png";
import Toast from "../../components/Toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logUserIn } from "../../redux/actions/login";
import { logOut } from "../../redux/slices/login";
export default function LoginPage() {
  /**-----------------------------------------------------------------*/
  /** CONSTANTS HERE                                                  */
  /**-----------------------------------------------------------------*/
  const navigate = useNavigate();
  const dispatch:any = useDispatch();

  /**-----------------------------------------------------------------*/
  /** STATE VARIABLES HERE                                            */
  /**-----------------------------------------------------------------*/
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showErrorLoginToast,setShowErrorLoginToast] = useState(false);

  /**-----------------------------------------------------------------*/
  /** REDUX SELECTORS HERE                                            */
  /**-----------------------------------------------------------------*/
  const {
    isLoginIn,
    successLoginIn,
    errorLoginIn,
    loggedIn,
    user_id,
    token,
    userType
  } = useSelector((state: any) => state.loginInReducer);

  /**-----------------------------------------------------------------*/
  /** USE EFFECTS HERE                                                */
  /**-----------------------------------------------------------------*/
  useEffect(() => {
    if (loggedIn === true) {
        if (userType === "WebUser") {
            navigate("/dashboard");
        }else{
            alert("This user does not have access to the control panel") 
            dispatch(logOut())         
        }
    }
  }, [loggedIn]);

  useEffect(()=>{
    if (errorLoginIn===true) {
        setShowErrorLoginToast(true)
    } else{
        setShowErrorLoginToast(false)
    }
  },[errorLoginIn])

  /**-----------------------------------------------------------------*/
  /** HANDLERS HERE                                                   */
  /**-----------------------------------------------------------------*/
  const dummyHandler = () => {};
  //Log In Handler
  const handleLogInClick = () => {
    if (username.length == 0 || password.length == 0) {
      alert("Username or Password can´t be empty ...");
    } else {
      //AQUI IRAN LOS LLAMADOS A LA API PARA LOGUEARSE ETC ETC DE MOMENTO NOS VAMOS AL DASHBOARD
      dispatch(logUserIn({ username, password }));
    }
  };
  //Username Change Handler
  const handleUsernamelChange = (event:any) => {
    setUsername(event.target.value);
  };

  //Password Change handler
  const handlePasswordChange = (event:any) => {
    setPassword(event.target.value);
  };

  /**-----------------------------------------------------------------*/
  /** VIEW RETURN HERE                                                */
  /**-----------------------------------------------------------------*/
  return (
    <div className="LoginPage--MainContainer">
      <div className="LoginPage--LogoContainer">
        <img
          src={MixologyIceLogoWhiteLetters}
          alt=""
          className="LoginPage--Logo"
        />
        <div className="section--name_primary">Control Panel</div>
      </div>
      <div className="LoginPage-LoginInputsContainer">
        <div className="LoginPage-InputContainer">
          <div className="input--label--primary">Username</div>
          <input onChange={handleUsernamelChange} type="text" />
        </div>
        <div className="LoginPage-InputContainer">
          <div className="input--label--primary">Password</div>
          <input onChange={handlePasswordChange} type="password" />
        </div>
        <div className="LoginPage-LoginButtonContainer">
          <button onClick={handleLogInClick} className="button--primary">LOGIN</button>
        </div>
      </div>
      <div className="LoginPage-FooterContainer">
        <div className="section--name_secondary">Mixology Ice © 2024</div>
      </div>
      {showErrorLoginToast===true?(<Toast
        duration={2000}
        message={
          "Invalid credentials. Kindly check and give it another try ;)"
        }
        type="error"
        onClose={dummyHandler}
        title={"Login Error"}
      />):(null)}
    </div>
  );
}
