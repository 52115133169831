import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  requestingToGetProductionStats: false,
  successRequestingToGetProductionStats: false,
  errorRequestingToGetProductionStats: false,
  errorRequestingToGetProductionStatsData: {},
  productionStatsData: {},
  unauthorized: true,
};

const getProductionStats = createSlice({
  name: "get-production-stats",
  initialState,
  reducers: {
    requestToGetProductionStats(state) {
      state.requestingToGetProductionStats = true;
    },
    successToGetProductionStats(state, action) {
      state.requestingToGetProductionStats = false;
      state.successRequestingToGetProductionStats = true;
      state.errorRequestingToGetProductionStats = false;
      state.productionStatsData = action.payload.data;
    },
    errorToGetProductionStats(state, action) {
      state.requestingToGetProductionStats = false;
      state.successRequestingToGetProductionStats = false;
      state.errorRequestingToGetProductionStats = true;
    },
  },
});

const { actions, reducer } = getProductionStats;

const {
  requestToGetProductionStats,
  successToGetProductionStats,
  errorToGetProductionStats,
} = actions;

export {
  requestToGetProductionStats,
  successToGetProductionStats,
  errorToGetProductionStats,
};

export default reducer;
