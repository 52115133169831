import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import "./DashboardPage.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import IceMachineSummaryChip from "../../components/IceMachineSummaryChip/IceMachineSummaryChip";
import { iceMachinesConfigIndex, requestToGetIceMachineConfigs } from "../../redux/actions/iceMachineConfigIndex";
import Modal from "../../components/Modal/Modal";
import IceMachineConfig from "../../components/IceMachineConfig/IceMachineConfigView";

//TODO: Cuando añada en la API el campo nombreMaquina debes tomarlo y utilizazrlo para pasarselo 
//      al IceMachineSummaryChip y se muestre correctamente el nombre de la maquina.
export default function DashboardPage() {
  /**-----------------------------------------------------------------*/
  /** CONSTANTS HERE                                                  */
  /**-----------------------------------------------------------------*/
  const navigate = useNavigate();
  const dispatch:any = useDispatch()

  /**-----------------------------------------------------------------*/
  /** STATE VARIABLOES HERE                                           */
  /**-----------------------------------------------------------------*/
  const [iceMachineConfigsList,setIceMachineConfigsList] = useState([]);

  /**-----------------------------------------------------------------*/
  /** REDUX SELECTORS HERE                                            */
  /**-----------------------------------------------------------------*/
  const {
    isLoginIn,
    successLoginIn,
    errorLoginIn,
    loggedIn,
    user_id,
    token,
    userType,
  } = useSelector((state: any) => state.loginInReducer);

  const {
    iceMachinConfigsData
  } = useSelector((state: any) => state.iceMachineConfigIndexReducer);

  /**-----------------------------------------------------------------------------------------------
   * USE EFFECTS HERE
   * ----------------------------------------------------------------------------------------------*/
  useEffect(() => {
    if (loggedIn === false) {
      navigate("/");
    }else{
      var tokenillo = sessionStorage.getItem("token");
     dispatch(iceMachinesConfigIndex(tokenillo));
    }
  }, [loggedIn]);

  useEffect(()=>{
    setIceMachineConfigsList(iceMachinConfigsData)
    console.log("iceMachinConfigsData")
    console.log(iceMachinConfigsData)
  },[iceMachinConfigsData]);

  /**-----------------------------------------------------------------*/
  /** HANDLERS HERE                                           */
  /**-----------------------------------------------------------------*/
  const dummyHandler = ()=>{};

  /**-----------------------------------------------------------------*/
  /** RENDER FUNCTIONS HERE                                           */
  /**-----------------------------------------------------------------*/
  const renderDashboard = () => {
    return (
      <div className="DashboardPage--MainContainer">
        <Header />
          <div className="Dashboard--Title section--name_primary">
            Dashboard
          </div>
          <div className="DasboardPage--IceMachines_Container">
          {iceMachineConfigsList.map((iceMachineConfig:any)=>{
            return(
              <IceMachineSummaryChip realIceMachineId={iceMachineConfig.id} iceMachineName={iceMachineConfig.nombreMaquina} iceMachineId={iceMachineConfig.user_id} noInternetCycles={iceMachineConfig.ciclosSinInternet} key={iceMachineConfig.user_id} />
            );
          })}
          </div>          
        <Footer />
      </div>
    );
  };
  return <div>{loggedIn === true ? renderDashboard() : null}</div>;
}
