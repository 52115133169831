import "./Header.css";
import MixologyIceLogo from "../../assets/MixologyIceLogo.png";
import { Tooltip } from "react-tooltip";
import { logOut } from "../../redux/slices/login";
import { useDispatch } from "react-redux";

export default function Header() {
    /**-----------------------------------------------------------------------------*/
    /** CONSTANTS HERE                                                              */
    /**-----------------------------------------------------------------------------*/
    const dispatch = useDispatch();
    /**-----------------------------------------------------------------------------*/
    /** HANDLERS HERE                                                               */
    /**-----------------------------------------------------------------------------*/
    const handleLogOutClick = ()=>{
        dispatch(logOut());
    }
  return (
    <div className="Header--MainContainer">
      <div className="Header--LogoTitleContainer">
        <img src={MixologyIceLogo} alt="" className="Header--Logo" />
        <div className="Header--TitleContanier">
          <div className="header--title_secondary">Mixology Ice</div>
          <div className="header--subtitle_primary">Control Panel</div>
        </div>
      </div>
      <div className="Header--ActionButtonsContainer">
        <span onClick={handleLogOutClick} className="material-icons Header--logout_button">logout</span>
      </div>

      <Tooltip anchorSelect=".Header--logout_button" place="bottom">
        Log Out!
      </Tooltip>
    </div>
  );
}
