import { createAction } from "@reduxjs/toolkit";
import { apiLocation,apiLocalURLRoot,apiServerURLRoot } from "../apiconfig";

import {
    requestToGetIceMachineConfigById as requestToGetIceMachineConfigByIdSlice,
    successToGetIceMachineConfigById as successToGetIceMachineConfigByIdSlice,
    errorToGetIceMachineConfigById as errorToGetIceMachineConfigByIdSlice,
} from "../slices/iceMachineConfigGetItemById"

export const requestToGetIceMachineConfigById = createAction("REQUEST_TO_GET_ICE_MACHINE_CONFIG_BY_ID");
export const successToGetIceMachineConfigById = createAction("SUCCESS_TO_GET_ICE_MACHINE_CONFIG_BY_ID");
export const errorToGetIceMachineConfigById = createAction("ERROR_TO_GET_ICE_MACHINE_CONFIG_BY_ID");

export const iceMachinesConfigGetItemById = (token:any,icemachineId:number)=>async (dispatch:any)=>{
    const requestOptions = {
        method: "GET",
        headers: {"Authorization": token,"Content-Type":"application/json"}
    }

    try {
        dispatch(requestToGetIceMachineConfigByIdSlice());
        let response
        let locationForApi = apiLocation
        if(locationForApi === "LOCAL"){
            response = await fetch(apiLocalURLRoot+"ice_machine_config/"+icemachineId,requestOptions);
        }
        else{
            response = await fetch(apiServerURLRoot+"ice_machine_config/"+icemachineId,requestOptions);
        }

        const data = await response.json();

        if (data.error){
            dispatch(errorToGetIceMachineConfigByIdSlice(data.error));
        } else{
            dispatch(successToGetIceMachineConfigByIdSlice({data}));
        }
    } catch (error) {
        dispatch(errorToGetIceMachineConfigByIdSlice({error}));
    }
};